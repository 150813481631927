import './Footer.css';

const FooterSection = () => {

    return(
        <div className="footerBody">

            <div className="copy">
                bedyk.pl &copy; 2024 - Wszelkie prawa zastrzeżone.
            </div>

        </div>
    )

}

export default FooterSection;