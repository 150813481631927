import React from "react";
import XO from "./XO/XO";

const RelaxSection = () => {

    return (
        <div className="body relaxSection">
            <XO />
        </div>
    )

}

export default RelaxSection;