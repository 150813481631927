

const Photo = () => {


    return (
        <>
            <div className={"photo"}>
                <img src="JPG.jpg" alt=""/>
            </div>
        </>
    )
};

export default Photo;